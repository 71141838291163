exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-architecture-cn-js": () => import("./../../../src/pages/architecture.cn.js" /* webpackChunkName: "component---src-pages-architecture-cn-js" */),
  "component---src-pages-architecture-js": () => import("./../../../src/pages/architecture.js" /* webpackChunkName: "component---src-pages-architecture-js" */),
  "component---src-pages-contact-cn-js": () => import("./../../../src/pages/contact.cn.js" /* webpackChunkName: "component---src-pages-contact-cn-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-floorplans-cn-js": () => import("./../../../src/pages/floorplans.cn.js" /* webpackChunkName: "component---src-pages-floorplans-cn-js" */),
  "component---src-pages-floorplans-js": () => import("./../../../src/pages/floorplans.js" /* webpackChunkName: "component---src-pages-floorplans-js" */),
  "component---src-pages-gallery-cn-js": () => import("./../../../src/pages/gallery.cn.js" /* webpackChunkName: "component---src-pages-gallery-cn-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-cn-js": () => import("./../../../src/pages/index.cn.js" /* webpackChunkName: "component---src-pages-index-cn-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interiors-cn-js": () => import("./../../../src/pages/interiors.cn.js" /* webpackChunkName: "component---src-pages-interiors-cn-js" */),
  "component---src-pages-interiors-js": () => import("./../../../src/pages/interiors.js" /* webpackChunkName: "component---src-pages-interiors-js" */),
  "component---src-pages-location-cn-js": () => import("./../../../src/pages/location.cn.js" /* webpackChunkName: "component---src-pages-location-cn-js" */),
  "component---src-pages-location-js": () => import("./../../../src/pages/location.js" /* webpackChunkName: "component---src-pages-location-js" */),
  "component---src-pages-old-register-cn-js": () => import("./../../../src/pages/old-register.cn.js" /* webpackChunkName: "component---src-pages-old-register-cn-js" */),
  "component---src-pages-privacy-policy-cn-js": () => import("./../../../src/pages/privacy-policy.cn.js" /* webpackChunkName: "component---src-pages-privacy-policy-cn-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-register-cn-js": () => import("./../../../src/pages/register.cn.js" /* webpackChunkName: "component---src-pages-register-cn-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-register-walk-in-cn-js": () => import("./../../../src/pages/register-walk-in.cn.js" /* webpackChunkName: "component---src-pages-register-walk-in-cn-js" */),
  "component---src-pages-register-walk-in-js": () => import("./../../../src/pages/register-walk-in.js" /* webpackChunkName: "component---src-pages-register-walk-in-js" */),
  "component---src-pages-team-cn-js": () => import("./../../../src/pages/team.cn.js" /* webpackChunkName: "component---src-pages-team-cn-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-thankyou-cn-js": () => import("./../../../src/pages/thankyou.cn.js" /* webpackChunkName: "component---src-pages-thankyou-cn-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */)
}

